export const state = {
  params: null, // company, group, context (string for Amplitude)
}

export const getters = {
  params: (state) => {
    return _.get(state, "params")
  },
}

export const actions = {
  reset: ({ commit, dispatch }) => {
    dispatch("app/setActiveDrawer", null, { root: true })
    commit("RESET_STATE")
  },
  setParams: ({ commit, dispatch }, params) => {
    if (params) {
      dispatch("app/setActiveDialog", { dialog: "activeModal" }, { root: true })

      // Note: Close company sheet with drawer opening above to prevent focus trapping.
      // See thread for context: https://github.com/radix-ui/primitives/issues/2544.
      if (params.isSheetView) {
        dispatch("companySheet/reset", null, { root: true })
      }
    }

    commit("SET_PARAMS", params)

    dispatch("app/setActiveDrawer", "AddDataDrawerWrapper", {
      root: true,
    })

    dispatch(
      "amplitude/logEvent",
      {
        name: "Add Relationship Clicked",
        properties: {
          context: params.context,
          company: params.company,
          group: params.group,
        },
      },
      { root: true }
    )
  },
}

export const mutations = {
  SET_PARAMS(state, params) {
    state.params = params
  },
  RESET_STATE(state) {
    state.params = null
  },
}
