import BaseState from "@utils/mixins/store/base/state"

export const state = {
  ...BaseState,
}

export const getters = {}

export const actions = {
  addAlert({ commit }, { alert } = {}) {
    commit("PUSH_ALERT", alert)
  },
  removeAlert({ commit }, { alert } = {}) {
    commit("DELETE_ALERT", alert)
  },
}

export const mutations = {
  PUSH_ALERT(state, alert) {
    let nextId = 0
    state.all.push({
      ...alert,
      id: nextId++,
    })
  },
  DELETE_ALERT(state, alertToRemove) {
    state.all = state.all.filter(
      (alert) => alert && alertToRemove && alert.id !== alertToRemove.id
    )
  },
}
