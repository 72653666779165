import {
  BaseState,
  BaseActions,
  BaseMutations,
  BaseGetters,
} from "@utils/mixins/store"

export const state = {
  ...BaseState,
}

export const getters = {
  ...BaseGetters,
}

export const actions = {
  ...BaseActions,
}

export const mutations = {
  ...BaseMutations,
}
