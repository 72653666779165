import {
  BaseState,
  BaseActions,
  BaseMutations,
  BaseGetters,
} from "@utils/mixins/store"

export const state = {
  ...BaseState,
  createReloadKey: 0,
}

export const getters = {
  ...BaseGetters,
  secureKey(state) {
    return _.get(state, "model.secret")
  },
  createReloadKey(state) {
    return _.get(state, "createReloadKey")
  },
}

export const actions = {
  ...BaseActions,
  progressCreateReloadKey({ commit }) {
    commit("PROGRESS_CREATE_RELOAD_KEY")
  },
}

export const mutations = {
  ...BaseMutations,
  PROGRESS_CREATE_RELOAD_KEY() {
    state.createReloadKey++
  },
}
