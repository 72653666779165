import axios from "axios"
import { BaseMutations } from "@utils/mixins/store"
import Anchor from "@mixins/fields/anchor"
import router from "@router"
import Vue from "vue"

export const state = {
  appInitCallbacksDone: false,
  activeFullscreen: false,
  activeModal: false,
  activePopup: false,
  activeDrawer: null,
  snackbar: null,
  lastPath: null,
  currentPath: null,
  reloadBaseSith: false,
  resetBaseSithSelected: false,
  activeBrowserTabIsHidden: false,
  activeToolbarTab: null,
  quickActionsState: {},
  appBarCollapsed: false,
  width: window.innerWidth,
}

export const getters = {
  appInitCallbacksDone: (state) => {
    return _.get(state, "appInitCallbacksDone")
  },
  width: (state) => {
    return _.get(state, "width")
  },
  lastPath: (state) => {
    return _.get(state, "lastPath")
  },
  lastSection: (state) => {
    const path = _.get(state, "lastPath")
    return path !== null ? Anchor.sectionFromPath(path) : path
  },
  currentPath: (state) => {
    return _.get(state, "currentPath")
  },
  currentSection: (state) => {
    const path = _.get(state, "currentPath")
    return path !== null ? Anchor.sectionFromPath(path) : path
  },
  activeFullscreen: (state) => {
    return _.get(state, "activeFullscreen")
  },
  activeModal: (state) => {
    return _.get(state, "activeModal")
  },
  activePopup: (state) => {
    return _.get(state, "activePopup")
  },
  activeDrawer: (state) => {
    return _.get(state, "activeDrawer")
  },
  reloadBaseSith: (state) => {
    return _.get(state, "reloadBaseSith")
  },
  resetBaseSithSelected: (state) => {
    return _.get(state, "resetBaseSithSelected")
  },
  snackbar: (state) => {
    return _.get(state, "snackbar")
  },
  activeBrowserTabIsHidden: (state) => {
    return state.activeBrowserTabIsHidden
  },
  activeToolbarTab: (state) => {
    return state.activeToolbarTab
  },
  appBarCollapsed: (state, getters, rootState, rootGetters) => {
    // App Bar Collapsed follows user session default behavior (0 = inactive, 1 = active),
    // and falls back to local state if the defaults are not loaded.
    let sessionDefaultBehavior = _.get(
      rootGetters["auth/defaultBehavior"],
      "app_sidebar"
    )

    return _.isNumber(sessionDefaultBehavior)
      ? sessionDefaultBehavior === 0
      : state.appBarCollapsed
  },

  //QUICK ACTIONS STATE
  quickActionsState: (state) => {
    return state.quickActionsState
  },
  quickActionsShow: (state) => {
    return _.get(state.quickActionsState, "show", null)
  },
  quickActionsKeyword: (state) => {
    return _.get(state.quickActionsState, "keyword", null)
  },
  quickActionsSearchContext: (state) => {
    return _.get(state.quickActionsState, "searchContext", null)
  },
  quickActionsPerformingAction: (state) => {
    return _.get(state.quickActionsState, "performingAction", null)
  },
}

export const actions = {
  initAppCaches({ dispatch, getters }) {
    // Dispatch non-auth related actions where the data is needed
    // on load, or reused across multiple aspects of the platform.
    // Runs only after a session has been validated in auth.js.'
    const currentPath = getters.currentPath

    if (!currentPath) {
      return
    }

    const onConnections = new RegExp("/connections", "i").test(currentPath)
    const onContainerShow =
      ![
        "/containers",
        "/containers/integrations",
        "/containers/data-providers",
      ].includes(currentPath) &&
      new RegExp("/containers/", "i").test(currentPath)

    if (!onConnections && !onContainerShow) {
      dispatch("connections/initConnections", null, { root: true })
    }
  },
  setAppWidth({ commit }) {
    commit("SET_APP_WIDTH", window.innerWidth)
  },
  setLastPath({ commit }, path) {
    commit("SET_LAST_PATH", path)
  },
  setCurrentPath({ commit }, path) {
    commit("SET_CURRENT_PATH", path)
  },
  setActiveDialog({ commit }, payload) {
    commit("SET_ACTIVE_DIALOG", payload)
  },
  setActivePopup({ commit }, payload) {
    commit("SET_ACTIVE_POPUP", payload)
  },
  setActiveDrawer({ commit }, payload) {
    commit("SET_ACTIVE_DRAWER", payload)
  },
  pluralizeActiveDialogTitle({ commit }) {
    commit("PLURALIZE_ACTIVE_DIALOG_TITLE")
  },
  reloadBaseSith({ commit }) {
    commit("RELOAD_BASE_SITH")
  },
  resetBaseSithSelected({ commit }) {
    commit("RESET_BASE_SITH_SELECTED")
  },
  setSnackbar({ commit }, payload) {
    commit("SET_SNACKBAR", payload)
  },
  closeAllActiveDialogs({ commit, dispatch }) {
    dispatch("companySheet/reset", null, { root: true })
    commit("CLOSE_ACTIVE_DIALOGS")
  },
  routeReplaceState({ commit, dispatch, getters, rootGetters }, queryParams) {
    let ssoOnly = rootGetters["auth/ssoOnly"]

    if (queryParams || ssoOnly) {
      let routePath = _.get(router, "currentRoute.path")

      if (queryParams) {
        routePath += `${queryParams}`
      }

      if (ssoOnly) {
        routePath += `${queryParams ? "&" : "?"}auth=sso`
      }

      return window.history.replaceState({}, null, routePath)
    }
  },
  trapBackNavigation({ rootGetters }) {
    if (!rootGetters.isMobile) return
    setTimeout(() => {
      for (let i = 0; i < 3; i++) {
        window.history.pushState(null, null, window.location.href)
      }
    }, 1000)
  },
  handleAxiosErrorResponse({ commit, dispatch, getters, rootGetters }, error) {
    return new Promise((resolve, reject) => {
      const errorResponseStatus = _.get(error, "response.status")
      let earlyEscape = false
      let errors = _.get(error, "response.data.errors")
      if (errors && _.get(errors, "length")) {
        for (let i = 0; i < errors.length; i++) {
          if (!_.get(errors[i], "status") || !_.get(errors[i], "code")) continue
          if (errors[i].status === 401) {
            // Ignore redirect to 404 with these 401 responses
            // Code: 120 => :invalid_integration_api_key
            // Code: 123 => :invalid_access_code
            if ([120, 123].includes(errors[i].code)) earlyEscape = true
          }
        }
      }

      if (earlyEscape) return resolve()
      const curRoute = _.get(router, "currentRoute.name")

      // ===
      // Server Unavailable
      // ===
      const abortCode = _.get(error, "config.signal.reason.code")

      if (
        (!error ||
          (!_.get(error, "response") && abortCode && abortCode !== 80085)) &&
        _.get(router, "currentRoute.name") !== "server-unavailable" &&
        _.get(getters, "activeModal.context") !== "AppServerUnavailableModal"
      ) {
        dispatch("setActiveDialog", {
          dialog: "activeModal",
          context: "AppServerUnavailableModal",
          title: "Connecting...",
        })
      }

      // ===
      // 503 - Maintenance Mode
      // ===
      else if (errorResponseStatus === 503) {
        commit("CLOSE_ACTIVE_DIALOGS")
        dispatch("auth/destroyCurrentSession", null, { root: true })
        router
          .push({
            name: "503",
            params: {
              message: _.get(error, "response.data.message"),
            },
          })
          .catch((e) => {})
      }

      // // ===
      // // 440 - Session Timeout
      // // ===
      else if (
        errorResponseStatus === 440 &&
        _.get(error, "response.data.errors[0].code") === 90
      ) {
        router.push({ name: "signin" }).catch((e) => {})
        dispatch("auth/destroyCurrentSession", null, {
          root: true,
        })
      }

      // ===
      // 401 - Admin Settings - Unauthorized request
      // ===
      else if (
        errorResponseStatus === 401 &&
        _.get(error, "response.data.errors[0].code") === 119
      ) {
        dispatch("auth/destroyCurrentSession", null, {
          root: true,
        })
        window.location = "/"
      }

      // ===
      // 401 - Unauthorized request
      // ===
      else if (
        errorResponseStatus === 401 &&
        curRoute !== "signin" &&
        curRoute !== "set_access_code"
      ) {
        router
          .push({
            name: rootGetters["auth/hasActiveSession"] ? "404" : "signin",
          })
          .catch((e) => {})
      }

      resolve()
    })
  },
  setActiveBrowserTabIsHidden({ commit, dispatch, rootGetters }, isHidden) {
    commit("SET_ACTIVE_BROWSER_TAB_IS_HIDDEN", isHidden)

    // ===
    // Actions to dispatch when a hidden BrowserTab becomes visible.
    // ===

    if (!isHidden && !rootGetters["sessionTimeout/timeoutModalActive"]) {
      // Verify the session state and caches are correct by comparing the cached activeGroup
      // against the Session activeGroup. If they differ, the currentRoute is reloaded.
      dispatch("auth/validateActiveBrowserTabSession", null, {
        root: true,
      }).catch((e) => {
        console.error(e)
      })
    }
  },
  runHealthCheck({ rootGetters }) {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: "healthcheck",
        baseURL: rootGetters["env/serverBaseUrl"],
      })
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  checkMaintenanceMode() {
    return new Promise((resolve, reject) => {
      axios
        .get("session")
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateDialogMeta({ commit }, payload) {
    commit("UPDATE_DIALOG_META", payload)
  },
  flagBadData({}, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("snowflakes/flag_bad_data", payload)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  setQuickActionsState({ commit }, obj) {
    commit("SET_QUICK_ACTIONS_STATE", obj)
  },
  setAppBarCollapsed({ commit, dispatch }, isCollapsed) {
    dispatch(
      "auth/setDefaultBehavior",
      {
        app_sidebar: isCollapsed ? 0 : 1,
      },
      { root: true }
    )
    commit("SET_APP_BAR_COLLAPSED", isCollapsed)
  },
  toggleOnboardingDelayed({ dispatch }) {
    setTimeout(() => {
      dispatch(
        "app/setActiveDialog",
        {
          dialog: "activeModal",
          action: "toggleDialog",
          context: "AppOnboardingModal",
        },
        { root: true }
      )
    }, 300)
  },
  toggleNotificationsDelayed({ dispatch, rootGetters }) {
    const group = rootGetters["auth/activeGroup"]
    if (group) {
      setTimeout(() => {
        dispatch(
          "notificationsDrawer/setParams",
          {
            group_id: _.get(group, "id"),
          },
          { root: true }
        )
      }, 300)
    }
  },
  appInitCallbacksDone({ commit }) {
    commit("SET_APP_INIT_CALLBACKS_DONE", true)
  },
}

export const mutations = {
  ...BaseMutations,
  SET_APP_INIT_CALLBACKS_DONE(state, val) {
    state.appInitCallbacksDone = val
  },
  CLOSE_ACTIVE_DIALOGS(state) {
    state.activeFullscreen = false
    state.activeModal = false
    state.activePopup = false
    state.activeDrawer = null
    state.snackbar = null
    state.activeBrowserTabIsHidden = null
    state.quickActionsState = {}
  },
  SET_ACTIVE_DIALOG(state, payload) {
    // Check if switching between dialogs
    if (state[payload.dialog] && !payload.switchContextWithinDialog) {
      state[payload.dialog] = false
    }
    // Ensure at least 1 context is set during the transaction,
    // ie. opening (null to cached) OR closing (cached to null).
    else if (
      !!_.get(payload, "context") ||
      !!_.get(state, `${payload.dialog}.context`)
    ) {
      Vue.set(state, payload.dialog, payload)
    }
  },
  SET_ACTIVE_POPUP(state, payload) {
    if (state.activePopup) {
      state.activePopup = false
    } else {
      state.activePopup = payload
    }
  },
  SET_APP_WIDTH(state, width) {
    state.width = width
  },
  SET_ACTIVE_DRAWER(state, payload) {
    state.activeDrawer = payload
  },
  PLURALIZE_ACTIVE_DIALOG_TITLE(state) {
    state.activeModal.title = _.pluralize(state.activeModal.title)
  },
  RELOAD_BASE_SITH(state) {
    state.reloadBaseSith = !state.reloadBaseSith
  },
  RESET_BASE_SITH_SELECTED(state) {
    state.resetBaseSithSelected = !state.resetBaseSithSelected
  },
  SET_SNACKBAR(state, payload) {
    state.snackbar = payload
  },
  SET_LAST_PATH(state, path) {
    state.lastPath = path
  },
  SET_CURRENT_PATH(state, path) {
    state.currentPath = path
  },
  SET_ACTIVE_BROWSER_TAB_IS_HIDDEN(state, isHidden) {
    state.activeBrowserTabIsHidden = isHidden
  },
  UPDATE_DIALOG_META(state, payload) {
    state[payload.dialog].meta = payload.meta
  },
  SET_APP_BAR_COLLAPSED(state, isCollapsed) {
    state.appBarCollapsed = isCollapsed
  },
  SET_QUICK_ACTIONS_STATE(state, obj) {
    // NOTE - saving for future changes. September 2024.
    // Default to companies search on show
    // if (obj.show && !obj.searchContext) {
    //   obj.searchContext = { model: "Company" }
    //   obj.keyword = ""
    // }
    state.quickActionsState = { ...state.quickActionsState, ...obj }
  },
}
