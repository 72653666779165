import Vue from "vue"

// dateRange = {
// 0: { from: null, to: null },
// 1: { from: 2022-01-10, to: 2022-01-10 }
// }
export const state = {
  dateRange: {},
}

export const getters = {
  getActivitiesDateRange: (state) => {
    return state.dateRange
  },
}

export const actions = {
  setDateRangeFilter: ({ commit }, payload) => {
    commit("SET_DATE_RANGE", payload)
  },
}

export const mutations = {
  SET_DATE_RANGE: (state, payload) => {
    Vue.set(state.dateRange, payload.context, payload.value)
  },
}
